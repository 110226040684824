import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

export type CheckboxOption = {
  label: string;
  value: any;
  disabled?: boolean;
};

@Component({
  selector: "record-form-item-format-checkboxes",
  templateUrl: "./item-format-checkboxes.component.html",
  styleUrls: ["./item-format-checkboxes.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatCheckboxesComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatCheckboxesComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Radio options
  @Input() options: CheckboxOption[] = [];
  @Input() orientation: "vertical" | "horizontal" = "vertical";

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  selectedValues: any[] = [];
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(private formBuilder: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any[]): void {
    this.selectedValues = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = "item-" + this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    // Retrieve placeholder
    if (this.formItem.getOptions()) {
      this.options = this.formItem.getOptions();
    }
  }

  handleChange(event) {
    if (event && event.checked) {
      this.onModelTouched();
      this.onModelChange(event.checked);
      this.writeValue(event.checked);
      this.onChange.emit(event.checked);
    }
  }
}
