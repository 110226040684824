import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { InputNumber } from "primeng/inputnumber";
import { v4 as v4 } from "uuid";

export type NumberMode = "decimal" | "currency";
export type CurrencyDisplay = "symbol" | "code";

@Component({
  selector: "record-form-item-format-number",
  templateUrl: "./item-format-number.component.html",
  styleUrls: ["./item-format-number.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatNumberComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatNumberComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild(InputNumber) inputNumber: InputNumber;

  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Number options
  @Input() format: boolean = true;
  @Input() mode: NumberMode = "decimal";
  @Input() prefix: string;
  @Input() suffix;
  @Input() currency: string;
  @Input() currencyDisplay: CurrencyDisplay = "symbol";
  @Input() minFractionDigits: number;
  @Input() maxFractionDigits: number;
  @Input() allowEmpty: boolean = true;
  @Input() locale: string;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() placeholder: string = "";
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number = 1;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = "item-" + this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;
    this.locale = this.i18nService.language;

    // Retrieve placeholder
    if (this.formItem.getPlaceholders()) {
      let placeholder = this.formItem.getPlaceholder(this.i18nService.language);
      placeholder && (this.placeholder = placeholder);
    }

    if (this.formItem.getFormatters()) {
      // Min formatter
      let minFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MIN);
      if (minFormatter && minFormatter.value) {
        let min = Number.parseFloat(minFormatter.value.toString());
        this.min = min;
        this.control?.setValue(min);
      }

      // Max formatter
      let maxFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX);
      if (maxFormatter && maxFormatter.value) {
        let max = Number.parseFloat(maxFormatter.value.toString());
        this.max = max;
      }

      // Step formatter
      let stepFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.STEP);
      if (stepFormatter && stepFormatter.value) {
        let step = Number.parseFloat(stepFormatter.value.toString());
        this.step = step;
      }

      // Number type formatter
      let numberTypeFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.NUMBER_TYPE);
      if (numberTypeFormatter && numberTypeFormatter.value) {
        let numberType = numberTypeFormatter.value.toString();
        if (numberType === "decimal") {
          let decimalCountFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.DECIMAL_COUNT);
          if (decimalCountFormatter && decimalCountFormatter.value) {
            let count = Number.parseFloat(decimalCountFormatter.value.toString());
            this.minFractionDigits = 0;
            this.maxFractionDigits = count;
          } else {
            this.minFractionDigits = 0;
            this.maxFractionDigits = 0;
          }
        } else if (numberType === "integer") {
          this.minFractionDigits = 0;
          this.maxFractionDigits = 0;
        } else {
          this.minFractionDigits = 0;
          this.maxFractionDigits = 2;
        }

        // Handle currency
        if (numberType === "currency") {
          this.mode = "currency";

          let currencyFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.CURRENCY);
          currencyFormatter && currencyFormatter.value && (this.currency = currencyFormatter.value.toString());

          let currencyDisplayFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.CURRENCY_DISPLAY);
          if (currencyDisplayFormatter && currencyDisplayFormatter.value) {
            let currencyDisplay = currencyDisplayFormatter.value.toString();
            ["symbol", "code"].includes(currencyDisplay) && (this.currencyDisplay = currencyDisplay as CurrencyDisplay);
          }
        }
      }
    }
  }

  handleChange(value) {
    this.onModelTouched();
    this.onModelChange(value);
    this.writeValue(value);
    this.onChange.emit(value);
  }
}
