<div class="preview">
  <p-divider align="left" [style]="{ '--surface-overlay': 'var(--surface-c)' }">
    <div class="inline-flex align-items-center font-semibold text-color-secondary">
      <i class="pi pi-eye mr-2"></i>
      <div class="preview__title">{{ "RECORD.form-item.preview.title" | translate : { "default": "Preview" } }}</div>
    </div>
  </p-divider>
  <div class="preview__description">
    {{
      "RECORD.form-item.preview.description"
        | translate
          : {
              "default":
                "This is an overview of the item as it will be presented to your recipients. You will be able to customize this item after adding it to your message."
            }
    }}
  </div>

  <div class="preview__content">
    <ng-container *ngTemplateOutlet="previewControl; context: { parentFormGroup }"></ng-container>
  </div>
</div>

<ng-template #previewControl let-parentFormGroup="parentFormGroup" [ngSwitch]="formItem.format">
  <!-- TEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.TEXT" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- MASKED TEXT control -->
      <record-form-item-format-masked-text
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-masked-text>
    </div>
  </ng-container>

  <!-- LONGTEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.LONGTEXT" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- TEXTAREA control -->
      <record-form-item-format-textarea
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-textarea>
    </div>
  </ng-container>

  <!-- NUMBER format -->
  <ng-container *ngSwitchCase="FormatEnum.NUMBER" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- NUMBER control -->
      <record-form-item-format-number
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-number>
    </div>
  </ng-container>

  <!-- CURRENCY format -->
  <ng-container *ngSwitchCase="FormatEnum.CURRENCY" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- NUMBER control -->
      <record-form-item-format-number
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-number>
    </div>
  </ng-container>

  <!-- DATE format -->
  <ng-container *ngSwitchCase="FormatEnum.DATE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [showTime]="false"
        [disabled]="true"
      ></record-form-item-format-calendar>
    </div>
  </ng-container>

  <!-- DATETIME format -->
  <ng-container *ngSwitchCase="FormatEnum.DATETIME" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [showTime]="true"
        [disabled]="true"
      ></record-form-item-format-calendar>
    </div>
  </ng-container>

  <!-- TIME format -->
  <ng-container *ngSwitchCase="FormatEnum.TIME" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>

      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [showTime]="true"
        [timeOnly]="true"
        [disabled]="true"
      ></record-form-item-format-calendar>
    </div>
  </ng-container>

  <!-- BOOLEAN format -->
  <ng-container *ngSwitchCase="FormatEnum.BOOLEAN" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- SWITCH control -->
        <ng-container *ngSwitchCase="ControlEnum.SWITCH" [formGroup]="parentFormGroup">
          <record-form-item-format-boolean-switch
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-boolean-switch>
        </ng-container>

        <!-- CHECKBOX control -->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-boolean-checkbox
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-boolean-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- DOCUMENTS format -->
  <ng-container *ngSwitchCase="FormatEnum.DOCUMENTS" [formGroup]="parentFormGroup">
    <div class="form-item__control" inert>
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- UPLOAD BUTTON control -->
        <ng-container *ngSwitchCase="ControlEnum.UPLOAD_BUTTON" [formGroup]="parentFormGroup">
          <record-form-item-format-upload-button
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-upload-button>
        </ng-container>

        <!-- DROPZONE control-->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-upload-dropzone
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-upload-dropzone>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- EMAIL format -->
  <ng-container *ngSwitchCase="FormatEnum.EMAIL" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <record-form-item-format-email
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-email>
    </div>
  </ng-container>

  <!-- PHONE format -->
  <ng-container *ngSwitchCase="FormatEnum.PHONE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- PHONE control -->
      <record-form-item-format-phone
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-phone>
    </div>
  </ng-container>

  <!-- ADDRESS format -->
  <ng-container *ngSwitchCase="FormatEnum.ADDRESS" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- ADDRESS FORM control -->
      <record-form-item-format-address-form
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-address-form>
    </div>
  </ng-container>

  <!-- CHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.CHOICE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <ng-container [ngSwitch]="control">
        <!-- RADIO BUTTONS control -->
        <ng-container *ngSwitchCase="ControlEnum.RADIO_BUTTONS" [formGroup]="parentFormGroup">
          <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
          <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
          <record-form-item-format-radio-buttons
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-radio-buttons>
        </ng-container>

        <ng-container *ngSwitchCase="ControlEnum.LISTBOX" [formGroup]="parentFormGroup">
          <!-- LISTBOX control -->
          <div class="form-item__control">
            <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
            <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
            <record-form-item-format-listbox
              [formControlName]="'item-' + formItem.uniqueId"
              [formItem]="formItem"
              [previewMode]="true"
              [disabled]="true"
            ></record-form-item-format-listbox>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <!-- DROPDOWN control -->
          <div class="form-item__control">
            <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
            <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
            <record-form-item-format-dropdown
              [formControlName]="'item-' + formItem.uniqueId"
              [formItem]="formItem"
              [previewMode]="true"
              [disabled]="true"
            ></record-form-item-format-dropdown>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- MULTICHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.MULTICHOICE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- LISTBOX control -->
        <ng-container *ngSwitchCase="ControlEnum.LISTBOX" [formGroup]="parentFormGroup">
          <record-form-item-format-listbox
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="true"
            [disabled]="true"
          ></record-form-item-format-listbox>
        </ng-container>

        <!-- CHECKBOXES control -->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-checkboxes
            [formControlName]="'item-' + formItem.uniqueId"
            [formItem]="formItem"
            [previewMode]="true"
            [disabled]="true"
          ></record-form-item-format-checkboxes>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- RATING format -->
  <ng-container *ngSwitchCase="FormatEnum.RATING" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- RATING control -->
      <record-form-item-format-rating
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [cancel]="true"
        [disabled]="true"
      ></record-form-item-format-rating>
    </div>
  </ng-container>

  <!-- RANGE format -->
  <ng-container *ngSwitchCase="FormatEnum.RANGE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- RANGE control -->
      <record-form-item-format-slider-range
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-slider-range>
    </div>
  </ng-container>

  <!-- DEFAULT format -->
  <div *ngSwitchDefault [formGroup]="parentFormGroup">
    <div class="flex flex-column gap-1">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- MASKED TEXT control -->
      <record-form-item-format-masked-text
        [formControlName]="'item-' + formItem.uniqueId"
        [formItem]="formItem"
        [previewMode]="true"
        [disabled]="true"
      ></record-form-item-format-masked-text>
    </div>
  </div>
</ng-template>
