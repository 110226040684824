import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { hmrBootstrap } from "./hmr";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// if (["dev", "staging", "prod"].includes(environment.sentry.environment)) {
//   Sentry.init({
//     dsn: environment.sentry.dsn,
//     integrations: [
//       new BrowserTracing({
//         tracingOrigins: environment.sentry.tracingOrigins,
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//     ],
//     environment: environment.sentry.environment,
//     release: environment.sentry.release,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: environment.sentry.tracesSampleRate,
//     autoSessionTracking: true,
//   });
// }

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module["hot"]) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error("HMR is not enabled for webpack-dev-server!");
    console.log("Are you using the --hmr flag for ng serve?");
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
