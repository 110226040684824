import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input-gg";
import * as lpn from "google-libphonenumber";
import { SharedValidators } from "@shared/validators";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-phone",
  templateUrl: "./item-format-phone.component.html",
  styleUrls: ["./item-format-phone.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatPhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecordFormItemFormatPhoneComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatPhoneComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild(NgxIntlTelInputComponent, { static: false }) telInput!: NgxIntlTelInputComponent;

  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Mask options
  @Input() separateDialCode: boolean = true;
  @Input() selectedCountryISO: CountryISO = CountryISO.France;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() placeholder: string = "";
  @Input() title: string = "";
  @Input() disabled: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
    this.telInput?.setDisabledState(disabled);
  }

  validate(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const isRequired = control.errors && control.errors.required === true;
    const error = { validatePhoneNumber: { valid: false } };

    let number: lpn.PhoneNumber;
    try {
      number = lpn.PhoneNumberUtil.getInstance().parse(control.value.number, control.value.countryCode);
    } catch (e) {
      if (isRequired) {
        return error;
      }
    }

    if (control.value) {
      // @ts-ignore
      if (!number) {
        return error;
      } else {
        if (!lpn.PhoneNumberUtil.getInstance().isValidNumberForRegion(number, control.value.countryCode)) {
          return error;
        }
      }
    }

    return null;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = "item-" + this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    // Retrieve placeholder
    if (this.formItem.getPlaceholders()) {
      let placeholder = this.formItem.getPlaceholder(this.i18nService.language);
      placeholder && (this.placeholder = placeholder);
    }
  }

  handleChange(value) {
    this.onModelTouched();
    this.onModelChange(value);
    this.onChange.emit(value);
  }
}
