import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MissingTranslationHandler, TranslateCompiler, TranslateModule } from "@ngx-translate/core";
import { NgxTranslateRoutesModule } from "ngx-translate-routes";
import { environment } from "@env/environment";
import { CoreModule } from "@core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "@shared/shared.module";
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { ConfirmationService, MessageService } from "primeng/api";
import { HomePageLayout } from "./layouts/home-page/home-page.layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiModule } from "./@ui/ui.module";
import { MainModule } from "./main/main.module";
import { SessionModule } from "./session/session.module";
import { MissingI18nHandler } from "@shared/i18n/missing-i18n.handler";
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { QuillModule } from "ngx-quill";
import { NgsContenteditableModule } from "@ng-stack/contenteditable";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxStripeModule } from "ngx-stripe";
import { ChartModule } from "primeng/chart";
import { RecordModule } from "@app/record/record.module";
import { KeycloakInitService } from "./@shared/services/keycloak-init.service";
import { provideMatomo } from "ngx-matomo-client";
import { withRouter } from "ngx-matomo-client";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingI18nHandler,
      },
    }),
    NgxTranslateRoutesModule.forRoot({
      enableTitleTranslate: true,
      enableRouteTranslate: false,
    }),
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register("safety-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerWhenStable:30000",
    }),
    QuillModule.forRoot({ suppressGlobalRegisterWarning: true }),
    NgsContenteditableModule,

    KeycloakAngularModule,
    NgxStripeModule.forRoot(environment.stripe.publishableKey),

    CoreModule,
    SharedModule,
    AppRoutingModule,

    MainModule,
    SessionModule,
    UiModule,

    ImageCropperModule,
    ChartModule,
    RecordModule,
  ],
  declarations: [AppComponent, HomePageLayout],
  providers: [
    ConfirmationService,
    MessageService,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakInitService],
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: environment.supportedLanguages },
    },
    provideMatomo(
      { trackerUrl: environment.matomo.host, siteId: environment.matomo.siteId, disabled: !environment.matomo.enabled },
      withRouter(),
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function initializeKeycloak(keycloakInitService: KeycloakInitService) {
  keycloakInitService.subscribeEvents();
  return () => keycloakInitService.init();
}
