<div
  class="flex gap-2"
  [ngClass]="{ 'flex-column': orientation === 'vertical', 'align-items-center': orientation === 'horizontal' }"
  [id]="inputId"
>
  <p-checkbox
    *ngFor="let option of options; let index = index"
    [name]="name"
    [inputId]="inputId + '-' + index"
    [title]="option.label"
    [disabled]="option.disabled"
    [label]="option.label"
    [value]="option.value"
    [ngModel]="selectedValues"
    (ngModelChange)="handleChange($event)"
    (onChange)="handleChange($event)"
  ></p-checkbox>
</div>
