<ng-container *ngTemplateOutlet="formItemControl; context: { parentFormGroup }"></ng-container>

<ng-template #formItemControl let-parentFormGroup="parentFormGroup" [ngSwitch]="formItem.format">
  <!-- TEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.TEXT" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label | capitalizeCase }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- MASKED TEXT control -->
      <record-form-item-format-masked-text
        formControlName="answer"
        [formItem]="formItem"
      ></record-form-item-format-masked-text>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- LONGTEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.LONGTEXT" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- TEXTAREA control -->
      <record-form-item-format-textarea
        formControlName="answer"
        [formItem]="formItem"
      ></record-form-item-format-textarea>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- NUMBER format -->
  <ng-container *ngSwitchCase="FormatEnum.NUMBER" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- NUMBER control -->
      <record-form-item-format-number formControlName="answer" [formItem]="formItem"></record-form-item-format-number>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- CURRENCY format -->
  <ng-container *ngSwitchCase="FormatEnum.CURRENCY" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- NUMBER control -->
      <record-form-item-format-number formControlName="answer" [formItem]="formItem"></record-form-item-format-number>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- DATE format -->
  <ng-container *ngSwitchCase="FormatEnum.DATE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        formControlName="answer"
        [formItem]="formItem"
        [showTime]="false"
      ></record-form-item-format-calendar>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- DATETIME format -->
  <ng-container *ngSwitchCase="FormatEnum.DATETIME" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        formControlName="answer"
        [formItem]="formItem"
        [showTime]="true"
      ></record-form-item-format-calendar>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- TIME format -->
  <ng-container *ngSwitchCase="FormatEnum.TIME" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>

      <!-- CALENDAR control -->
      <record-form-item-format-calendar
        formControlName="answer"
        [formItem]="formItem"
        [showTime]="true"
        [timeOnly]="true"
      ></record-form-item-format-calendar>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- BOOLEAN format -->
  <ng-container *ngSwitchCase="FormatEnum.BOOLEAN" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- SWITCH control -->
        <ng-container *ngSwitchCase="ControlEnum.SWITCH" [formGroup]="parentFormGroup">
          <record-form-item-format-boolean-switch
            formControlName="answer"
            [formItem]="formItem"
          ></record-form-item-format-boolean-switch>
        </ng-container>

        <!-- CHECKBOX control -->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-boolean-checkbox
            formControlName="answer"
            [formItem]="formItem"
          ></record-form-item-format-boolean-checkbox>
        </ng-container>
      </ng-container>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- DOCUMENTS format -->
  <ng-container *ngSwitchCase="FormatEnum.DOCUMENTS" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- UPLOAD BUTTON control -->
        <ng-container *ngSwitchCase="ControlEnum.UPLOAD_BUTTON" [formGroup]="parentFormGroup">
          <record-form-item-format-upload-button
            formControlName="answer"
            [record]="record"
            [formItem]="formItem"
            [uploads]="uploads"
            [label]="
              answerAttachments?.length > 0
                ? ('COMPONENTS.file-upload.title.add-more' | translate : { 'default': 'Add more files' })
                : null
            "
            uploadListVariant="inline"
            (onFilesAdded)="handleFilesAdded($event)"
            (onUploadRemove)="handleUploadRemove($event)"
          ></record-form-item-format-upload-button>
        </ng-container>

        <!-- DROPZONE control-->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-upload-dropzone
            formControlName="answer"
            [record]="record"
            [formItem]="formItem"
            [uploads]="uploads"
            uploadListVariant="inline"
            (onFilesAdded)="handleFilesAdded($event)"
            (onUploadRemove)="handleUploadRemove($event)"
          ></record-form-item-format-upload-dropzone>
        </ng-container>
      </ng-container>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
      <!-- Display already sent answers -->
      <details
        class="border-1 border-round surface-c mt-1"
        style="border-color: var(--border-color)"
        *ngIf="answerAttachments?.length > 0"
      >
        <summary class="cursor-pointer px-3 py-2 text-sm">
          {{ "COMPONENTS.file-upload.uploaded-files.label" | translate : { default: "Submitted Files" } }}
        </summary>
        <div class="auto-grid px-3 pb-3">
          <div class="border-1 surface-border border-round m-0" *ngFor="let attachment of answerAttachments">
            <main-file-download [file]="attachment.file!" [record]="record"></main-file-download>
          </div>
        </div>
      </details>
    </div>
  </ng-container>

  <!-- EMAIL format -->
  <ng-container *ngSwitchCase="FormatEnum.EMAIL" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- EMAIL control -->
      <record-form-item-format-email formControlName="answer" [formItem]="formItem"></record-form-item-format-email>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- PHONE format -->
  <ng-container *ngSwitchCase="FormatEnum.PHONE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- PHONE control -->
      <record-form-item-format-phone formControlName="answer" [formItem]="formItem"></record-form-item-format-phone>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- ADDRESS format -->
  <ng-container *ngSwitchCase="FormatEnum.ADDRESS" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- ADDRESS FORM control -->
      <record-form-item-format-address-form
        formControlName="answer"
        [formItem]="formItem"
      ></record-form-item-format-address-form>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- CHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.CHOICE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <ng-container [ngSwitch]="control">
        <!-- RADIO BUTTONS control -->
        <ng-container *ngSwitchCase="ControlEnum.RADIO_BUTTONS" [formGroup]="parentFormGroup">
          <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
          <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
          <record-form-item-format-radio-buttons
            formControlName="answer"
            [formItem]="formItem"
          ></record-form-item-format-radio-buttons>
        </ng-container>

        <ng-container *ngSwitchCase="ControlEnum.LISTBOX" [formGroup]="parentFormGroup">
          <!-- LISTBOX control -->
          <div class="form-item__control">
            <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
            <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
            <record-form-item-format-listbox
              formControlName="answer"
              [formItem]="formItem"
            ></record-form-item-format-listbox>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <!-- DROPDOWN control -->
          <div class="form-item__control">
            <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
            <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
            <record-form-item-format-dropdown
              formControlName="answer"
              [formItem]="formItem"
            ></record-form-item-format-dropdown>
          </div>
        </ng-container>
      </ng-container>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- MULTICHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.MULTICHOICE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <ng-container [ngSwitch]="control">
        <!-- LISTBOX control -->
        <ng-container *ngSwitchCase="ControlEnum.LISTBOX" [formGroup]="parentFormGroup">
          <record-form-item-format-listbox
            formControlName="answer"
            [formItem]="formItem"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="true"
          ></record-form-item-format-listbox>
        </ng-container>

        <!-- CHECKBOXES control -->
        <ng-container *ngSwitchDefault [formGroup]="parentFormGroup">
          <record-form-item-format-checkboxes
            formControlName="answer"
            [formItem]="formItem"
          ></record-form-item-format-checkboxes>
        </ng-container>
      </ng-container>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- RATING format -->
  <ng-container *ngSwitchCase="FormatEnum.RATING" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- RATING control -->
      <record-form-item-format-rating
        formControlName="answer"
        [formItem]="formItem"
        [cancel]="true"
      ></record-form-item-format-rating>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- RANGE format -->
  <ng-container *ngSwitchCase="FormatEnum.RANGE" [formGroup]="parentFormGroup">
    <div class="form-item__control">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- RANGE control -->
      <record-form-item-format-slider-range
        formControlName="answer"
        [formItem]="formItem"
      ></record-form-item-format-slider-range>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </ng-container>

  <!-- DEFAULT format -->
  <div *ngSwitchDefault [formGroup]="parentFormGroup">
    <div class="flex flex-column gap-1">
      <label class="form-item__control__label" [for]="'item-' + formItem.uniqueId">{{ label }}</label>
      <p class="form-item__control__description" *ngIf="description" [innerHTML]="description | safeHtml"></p>
      <!-- MASKED TEXT control -->
      <record-form-item-format-masked-text
        formControlName="answer"
        [formItem]="formItem"
      ></record-form-item-format-masked-text>
      <form-item-validation-messages
        *ngIf="answersWithMessages?.length > 0"
        [answersWithMessages]="answersWithMessages"
      ></form-item-validation-messages>
    </div>
  </div>
</ng-template>
