import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";
import { WindowHelper } from "@app/@shared/helpers/window.helper";
import FileModel from "@app/@shared/models/file/file.model";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordModel from "@app/@shared/models/record/record.model";
import WrappedKeyModel from "@app/@shared/models/vault/wrapped-key.model";
import { CryptographyService } from "@app/@shared/services/cryptography.service";
import { FilesAPIService, StartUploadParam, StartUploadResult } from "@app/@shared/services/files-api.service";
import { FilesService } from "@app/@shared/services/files.service";
import { NavigationService } from "@app/@shared/services/navigation.service";
import { UploadService } from "@app/@shared/services/upload.service";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { isEqual, orderBy } from "lodash";
import { UploadState, UploadxService } from "ngx-uploadx";
import { BehaviorSubject, map, Observable, pairwise, startWith, Subscription, tap } from "rxjs";
import { RecordFormItemControlComponent } from "../form-item-control/form-item-control.component";
import { ConfirmationService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

export type RecordAnswerFormItem = {
  itemIdentifier: string;
  answer?: any;
  files?: string[];
};

export type RecordAnswerFormSubmitEvent = {
  answers: RecordAnswerFormItem[];
  files?: FileModel[];
};

type RecordAnswerFormValue = {
  answers: RecordAnswerFormItem[];
};

type UploadAnswer = {
  itemIdentifier: string;
  files: FileModel[];
};

@UntilDestroy()
@Component({
  selector: "record-form-answer-form",
  templateUrl: "./form-answer-form.component.html",
  styleUrls: ["./form-answer-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecordFormAnswerFormComponent implements OnInit, OnChanges {
  @ViewChildren(RecordFormItemControlComponent) formItemControls: QueryList<RecordFormItemControlComponent>;

  @Input() message: RecordMessageModel;
  @Input() wrappedKeys: WrappedKeyModel[];
  @Input() isSubmitting: boolean = false;

  @Output() onSubmit: EventEmitter<RecordAnswerFormSubmitEvent> = new EventEmitter<RecordAnswerFormSubmitEvent>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  decryptedBody: string;
  files: FileModel[] = [];
  form: UntypedFormGroup;
  formDisabled: boolean = true;
  isDecrypting: boolean = false;
  initialValue: RecordAnswerFormValue;
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  uploadAnswers: UploadAnswer[] = [];
  uploadState$: Observable<UploadState>;
  uploads: UploadState[] = [];
  messageExpanded: boolean = true;

  get record() {
    return this.record$.getValue();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadxService: UploadxService,
    private uploadService: UploadService,
    private navigationService: NavigationService,
    private cryptographyService: CryptographyService,
    private filesService: FilesService,
    private translateService: TranslateService,
    private filesApiService: FilesAPIService,
    private recordService: RecordService,
    private confirmationService: ConfirmationService,
  ) {
    // Initialize upload state
    this.uploadState$ = this.uploadxService.init(this.uploadService.getDefaultOptions());

    // Put this in a BehaviorSubject to access its value at any time
    this.recordService.record$.subscribe(this.record$);
  }

  ngOnInit(): void {
    this.uploadState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.handleUploadStateChanged(state);
    });

    this.buildForm();

    this.initialValue = this.form.value;

    // Listen to form.valueChanges to set dirty state, disable submit button, and enable navigation prevent
    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        pairwise(),
        map(([prev, next]) => !isEqual(next, this.initialValue) || this.uploads.length > 0),
        untilDestroyed(this),
      )
      .subscribe((dirty) => {
        this.formDisabled = !dirty || (dirty && (this.form.invalid || this.isFormAnswersEmpty()));
        if (dirty) {
          WindowHelper.enableWarningOnClose();
          this.navigationService.preventNavigation = true;
        } else {
          WindowHelper.disableWarningOnClose();
          this.navigationService.preventNavigation = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wrappedKeys || changes.message) {
      this.decryptBody();
    }

    if (changes.message) {
      this.buildForm();
    }
  }

  get unansweredFormItems() {
    return orderBy(
      this.message.formItems.filter((formItem: RecordMessageFormItemModel) => this.shouldFormItemBeDisplayed(formItem)),
      "position",
      "asc",
    );
  }

  get answeredFormItems() {
    return orderBy(
      this.message.formItems.filter((formItem: RecordMessageFormItemModel) =>
        Boolean(this.getAnswerValue(formItem.itemIdentifier)),
      ),
      "position",
      "asc",
    );
  }

  /**
   * Evaluate if a formItem form field should be displayed
   * 1. at least one valid answer, waiting for validation after being answered -> hide the field
   * 2. type Documents or all answers invalid or simply does not have an answer yet -> display the field
   * @param formItem
   * @returns
   */
  shouldFormItemBeDisplayed(formItem: RecordMessageFormItemModel): boolean {
    const answers = this.getAnswers(formItem.itemIdentifier),
      hasNoAnswer = !Boolean(this.getAnswerValue(formItem.itemIdentifier)),
      hasValidAnswer = answers?.some((answer) => answer.isValid === true),
      everyAnswersInvalid = answers?.every((answer) => answer.isValid === false),
      hasAnswerWaitingForValidation = answers?.some((answer) => answer.isValid === null),
      isDocuments = formItem.format === DataTypeFormatEnum.DOCUMENTS;

    if (isDocuments || hasNoAnswer || everyAnswersInvalid) return true;
    if (hasValidAnswer || hasAnswerWaitingForValidation) return false;
    return false;
  }

  isFormAnswersEmpty() {
    return (
      this.form.value.answers !== null &&
      this.form.value.answers !== undefined &&
      this.form.value.answers.every(
        (a: RecordAnswerFormItem) => a.answer === null && (a.files === null || !a.files?.length),
      ) &&
      this.uploads.length === 0
    );
  }

  buildForm() {
    this.form = this.formBuilder.group({
      answers: this.formBuilder.array([]),
    });

    this.unansweredFormItems.forEach((formItem: RecordMessageFormItemModel) => {
      this.buildFormItem(formItem);
    });

    // Subscribe to valueChanges
    this.form.valueChanges
      .pipe(startWith(this.form.value), pairwise(), untilDestroyed(this))
      .subscribe(([prevValue, newValue]) => this.form.patchValue(newValue, { emitEvent: false }));
  }

  buildFormItem(formItem: RecordMessageFormItemModel) {
    let group: UntypedFormGroup = this.formBuilder.group({
      itemIdentifier: [formItem.itemIdentifier, [Validators.required]],
      answer: [this.getAnswerValue(formItem.itemIdentifier)?.content?.value || null, [this.answerNotEmptyValidator()]],
    });

    if (formItem.format === DataTypeFormatEnum.DOCUMENTS) {
      group.addControl("files", new UntypedFormControl([], []));
    }

    this.answerControls.push(group);
  }

  answerNotEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;

      if (value === null || value instanceof Date) {
        return null;
      }

      const error = { empty: true };

      if (value === undefined) return error;

      if (Array.isArray(value) || typeof value === "string") {
        if (value.length === 0) {
          return error;
        } else {
          return null;
        }
      } else if (typeof value === "object") {
        if (Object.values(value).every((val: any) => !Boolean(val) || val === "")) {
          return error;
        } else {
          return null;
        }
      }

      return null;
    };
  }

  get answerControls() {
    return this.form.get("answers") as UntypedFormArray;
  }

  answerControl(index: number) {
    return this.answerControls.controls[index] as UntypedFormGroup;
  }

  getAnswerValue(formItemIdentifier: string): RecordMessageAnswerModel | undefined {
    return this.message.getLastFormAnswer(formItemIdentifier);
  }
  getAnswers(formItemIdentifier: string): RecordMessageAnswerModel[] {
    return this.message.getFormAnswers(formItemIdentifier);
  }

  getFormItemAttachments(
    formItemIdentifier: string,
    answer?: RecordMessageAnswerModel,
  ): RecordMessageAttachmentModel[] | undefined {
    const fileIds = answer?.content.files,
      attachments = this.message.getFormAnswerAttachments(formItemIdentifier);
    return fileIds?.length ? attachments?.filter(({ file }) => fileIds.includes(file.fileIdentifier)) : attachments;
  }

  @HostListener("click", ["$event"])
  handleClick(event) {
    let parentElement = event.target.parentElement;

    if (parentElement && parentElement.classList.contains("ql-form-item")) {
      let uniqueId = parentElement.getAttribute("data-identifier");
      let element = document.querySelector(`.form-answer #item-${uniqueId}`);
      element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }

  cancelUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "cancel", uploadId });
  }

  pauseUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "pause", uploadId });
  }

  uploadUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "upload", uploadId });
  }

  getUploads(itemIdentifier: string) {
    let uploadIds = this.uploadService.getUploadIdsByFormItemIdentifier(itemIdentifier, this.uploadxService.queue);
    return this.uploads.filter((upload: UploadState) => uploadIds.includes(upload.uploadId));
  }

  handleUploadStateChanged(state: UploadState) {
    const target = this.uploads.find((item) => item.uploadId === state.uploadId);

    if (target) {
      // Updates state
      Object.assign(target, state);

      if (state.status === "cancelled") {
        // Deletes from uploads if cancelled
        this.uploads = this.uploads.filter((item) => item.uploadId !== state.uploadId);
        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      } else if (state.status === "complete") {
        // If complete add file to answers
        let metadata = this.uploadService.getQueueUploaderMetadata(state.uploadId, this.uploadxService.queue);
        let fileIdentifier = metadata["fileIdentifier"].toString();
        let itemIdentifier = metadata["itemIdentifier"].toString();
        let chunkHashes = metadata["hashes"] as string[];

        this.filesApiService
          .recordFinishUpload(fileIdentifier, this.record.recordIdentifier, "", chunkHashes)
          .pipe(untilDestroyed(this))
          .subscribe((file: FileModel) => {
            this.files.push(file);

            let answers = this.form.value.answers;

            let answerIndex = answers.findIndex(
              (answerItem: RecordAnswerFormItem) => answerItem.itemIdentifier === itemIdentifier,
            );
            if (answerIndex > -1) {
              answers[answerIndex].value = file.name;
              answers[answerIndex].files = [...(answers[answerIndex].files ?? []), file.fileIdentifier];
            }

            this.form.patchValue(
              {
                ...this.form.value,
                answers,
              },
              { emitEvent: false },
            );

            if (this.uploadFinished) {
              let { answers } = this.form.value as RecordAnswerFormSubmitEvent;
              this.emitSubmitEvent({ answers, files: this.files });
            }
          });
      }
    } else {
      // Add file to uploads if not already added
      this.uploads.push(state);
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  get uploadFinished() {
    let remainingUploads = this.uploads.filter((upload: UploadState) =>
      ["added", "queue", "uploading", "paused", "retry"].includes(upload.status),
    );
    let completedUploads = this.uploads.filter((upload: UploadState) => ["complete"].includes(upload.status));

    // If no remaining uploads & all uploads completed
    return remainingUploads.length === 0 && this.files.length === completedUploads.length;
  }

  startUpload(): void {
    if (this.record) {
      let startUploadParams: StartUploadParam[] = this.uploads
        .filter((upload) => upload.status == "added")
        .map((upload) => {
          let param: StartUploadParam = {
            uploadId: upload.uploadId,
            fileName: upload.file.name,
            fileMimeType: upload.file.type,
            fileSize: upload.file.size,
          };
          return param;
        });

      const subscription: Subscription = this.filesService
        .recordStartAnswerUploads(this.record, startUploadParams)
        .subscribe((results: StartUploadResult[]) => {
          results.forEach((r: StartUploadResult) => {
            this.uploadService.setQueueUploaderMetadata(
              r.uploadId,
              { fileIdentifier: r.identifier },
              this.uploadxService.queue,
            );
            this.uploadUpload(r.uploadId);
          });
          subscription.unsubscribe();
        });
    }
  }

  submit() {
    if (this.formDisabled) {
      return null;
    }
    this.confirmationService.confirm({
      header: this.translateService.instant("RECORD.actions.submit-answers.header", {
        default: "Finalize my answers?",
      }),
      message: this.translateService.instant("RECORD.actions.submit-answers.body", {
        default: "After sending, they cannot be modified.",
      }),
      acceptLabel: this.translateService.instant("RECORD.actions.submit-answers.buttons.yes", {
        default: "Yes, submit",
      }),
      rejectLabel: this.translateService.instant("RECORD.actions.submit-answers.buttons.no", { default: "No, cancel" }),
      acceptButtonStyleClass: "p-button-sm",
      rejectButtonStyleClass: "p-button-outlined p-button-sm",
      acceptIcon: "hidden",
      rejectIcon: "hidden",
      accept: () => {
        if (this.uploads?.length > 0) {
          this.startUpload();
        } else {
          let { answers } = this.form.value as RecordAnswerFormValue;
          this.emitSubmitEvent({ answers });
        }
      },
    });
  }

  emitSubmitEvent(event: RecordAnswerFormSubmitEvent) {
    this.onSubmit.emit(event);
    WindowHelper.disableWarningOnClose();
    this.navigationService.preventNavigation = false;
  }

  cancel() {
    this.onCancel.emit();
  }

  decryptBody() {
    let body = this.message.body;

    if (body) {
      if (
        [MessageFormatEnum.ENCRYPTED_TEXT, MessageFormatEnum.ENCRYPTED_HTML].includes(this.message.format) &&
        this.wrappedKeys != null
      ) {
        this.isDecrypting = true;
        this.cryptographyService
          .decryptText(this.wrappedKeys, body)
          .then((decrypted) => {
            this.decryptedBody = decrypted;
          })
          .finally(() => {
            this.isDecrypting = false;
          });
      } else {
        this.decryptedBody = body;
      }
    }
  }

  ngOnDestroy() {
    this.uploadxService.disconnect();
  }
}
