<div [id]="'item-' + formItem.uniqueId">
  <ng-container *ngIf="!isDecrypting; else loadingTemplate">
    <ng-container *ngTemplateOutlet="formItemAnswer"></ng-container>
  </ng-container>
</div>

<ng-template #formItemAnswerHeader>
  <div
    class="flex sm:flex-row justify-content-between align-items-center gap-2"
    [ngClass]="{ 'flex-column': !(enableAnswerShortcut && answerValue.isValid === false && !enableAnswersValidation) }"
  >
    <span *ngIf="showLabel" class="form-item__control__label clamp">{{ label | capitalizeCase }}</span>

    <!-- For managers only display validation controls -->
    <div
      class="flex gap-2 align-items-center justify-content-end"
      style="--border-radius: 20px"
      *ngIf="enableAnswersValidation"
    >
      <button
        type="button"
        icon="msr-icon-check_circle font-bold hidden sm:block"
        pButton
        class="p-button-sm py-1 px-2 flex align-items-center justify-content-center no-shadow p-button-success"
        [label]="'RECORD.form-item-answer.validation.buttons.validate' | translate : { default: 'Approve' }"
        (click)="validateFormAnswer()"
        [disabled]="isUpdatingValidationStatus$ | async"
        [ngClass]="{
          'p-button-outlined': answerValue.isValid !== true,
          '': answerValue.isValid === true
        }"
      ></button>
      <button
        type="button"
        icon="msr-icon-cancel font-bold hidden sm:block"
        pButton
        class="p-button-sm py-1 px-2 flex align-items-center justify-content-center no-shadow p-button-danger"
        [label]="'RECORD.form-item-answer.validation.buttons.reject' | translate : { default: 'Reject' }"
        (click)="validationMessagePromptPanel.show($event)"
        tooltipStyleClass="generic-tooltip"
        [disabled]="isUpdatingValidationStatus$ | async"
        [ngClass]="{
          'p-button-outlined': answerValue.isValid !== false,
          '': answerValue.isValid === false
        }"
      ></button>
    </div>

    <!-- Shortcut towards answer dialog -->
    <ng-container *ngIf="enableAnswerShortcut && answerValue.isValid === false && !enableAnswersValidation">
      <button
        icon="text-sm pi pi-external-link font-bold"
        [label]="'COMMON.buttons.answer-form' | translate : { 'default': 'Answer' }"
        pButton
        type="button"
        (click)="handleShowAnswerForm()"
        class="p-button-sm border-noround p-0 p-button-text flex-shrink-0"
      ></button>
    </ng-container>
  </div>
  <!-- Validation reason -->
  <form-item-validation-messages
    *ngIf="answerValue.validationMessage"
    [answersWithMessages]="[answerValue]"
  ></form-item-validation-messages>
</ng-template>

<ng-template #formItemAnswer [ngSwitch]="formItem.format">
  <!-- TEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.TEXT">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- LONGTEXT format -->
  <ng-container *ngSwitchCase="FormatEnum.LONGTEXT">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- NUMBER format -->
  <ng-container *ngSwitchCase="FormatEnum.NUMBER">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- CURRENCY format -->
  <ng-container *ngSwitchCase="FormatEnum.CURRENCY">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- DATE format -->
  <ng-container *ngSwitchCase="FormatEnum.DATE">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- DATETIME format -->
  <ng-container *ngSwitchCase="FormatEnum.DATETIME">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>

      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- TIME format -->
  <ng-container *ngSwitchCase="FormatEnum.TIME">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- BOOLEAN format -->
  <ng-container *ngSwitchCase="FormatEnum.BOOLEAN">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- DOCUMENTS format -->
  <ng-container *ngSwitchCase="FormatEnum.DOCUMENTS">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <div
          *ngIf="answerAttachments?.length > 0"
          class="auto-grid"
          style="--grid-placement: auto-fit; --grid-min-item-size: 22rem"
        >
          <div
            class="border-1 surface-border border-round m-0"
            *ngFor="let attachment of answerAttachments"
            style="--border-radius: 15px"
          >
            <main-file-download
              [showValidationBadge]="showValidationBadge"
              [isValidated]="answerValue.isValid"
              [file]="attachment.file!"
              [record]="record"
            ></main-file-download>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- EMAIL format -->
  <ng-container *ngSwitchCase="FormatEnum.EMAIL">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- PHONE format -->
  <ng-container *ngSwitchCase="FormatEnum.PHONE">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- ADDRESS format -->
  <ng-container *ngSwitchCase="FormatEnum.ADDRESS">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span [innerHTML]="formattedAnswerValue | safeHtml"></span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- CHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.CHOICE">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngSwitch]="control">
        <!-- RADIO BUTTONS control -->
        <ng-container *ngSwitchCase="ControlEnum.RADIO_BUTTONS">
          <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
          <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
            <span>
              {{ formattedAnswerValue }}
            </span>
            <ui-badge
              class="hidden sm:block ml-auto"
              *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
              [variant]="answerValue.isValid === true ? 'success' : 'error'"
              size="small"
              [label]="
                answerValue.isValid === true
                  ? ('RECORD.form-item-answer.validation.approval.status' | translate)
                  : ('RECORD.form-item-answer.validation.rejection.status' | translate)
              "
            ></ui-badge>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ControlEnum.LISTBOX">
          <!-- LISTBOX control -->
          <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
            <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
            <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
              <span>
                {{ formattedAnswerValue }}
              </span>
              <ui-badge
                class="hidden sm:block ml-auto"
                *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
                [variant]="answerValue.isValid === true ? 'success' : 'error'"
                size="small"
                [label]="
                  answerValue.isValid === true
                    ? ('RECORD.form-item-answer.validation.approval.status' | translate)
                    : ('RECORD.form-item-answer.validation.rejection.status' | translate)
                "
              ></ui-badge>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <!-- DROPDOWN control -->
          <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
            <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
            <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
              <span>
                {{ formattedAnswerValue }}
              </span>
              <ui-badge
                class="hidden sm:block ml-auto"
                *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
                [variant]="answerValue.isValid === true ? 'success' : 'error'"
                size="small"
                [label]="
                  answerValue.isValid === true
                    ? ('RECORD.form-item-answer.validation.approval.status' | translate)
                    : ('RECORD.form-item-answer.validation.rejection.status' | translate)
                "
              ></ui-badge>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- MULTICHOICE format -->
  <ng-container *ngSwitchCase="FormatEnum.MULTICHOICE">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <div class="flex flex-column gap-1">
          <div *ngFor="let choice of formattedAnswerValue">{{ getOptionLabel(choice) }}</div>
        </div>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- RATING format -->
  <ng-container *ngSwitchCase="FormatEnum.RATING">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <div class="flex align-items-center gap-2">
          <i
            class="pi"
            *ngFor="let star of [].constructor(ratingMax); let index = index"
            [ngClass]="{ 'pi-star-fill': index < ratingValue, 'pi-star': index >= ratingValue }"
          ></i>
        </div>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- RANGE format -->
  <ng-container *ngSwitchCase="FormatEnum.RANGE">
    <div class="form-item__control" [id]="'item-' + formItem.uniqueId">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span [innerHTML]="formattedAnswerValue | safeHtml"></span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </ng-container>

  <!-- DEFAULT format -->
  <div *ngSwitchDefault>
    <div class="flex flex-column gap-1">
      <ng-container [ngTemplateOutlet]="formItemAnswerHeader"></ng-container>
      <div class="form-item__answer" [ngClass]="'form-item__answer--' + formItem.format">
        <span class="form-item__answer-value">
          {{ formattedAnswerValue }}
        </span>
        <ui-badge
          class="block ml-auto"
          *ngIf="showValidationBadge && [false, true].includes(answerValue.isValid)"
          [variant]="answerValue.isValid === true ? 'success' : 'error'"
          size="small"
          [label]="
            answerValue.isValid === true
              ? ('RECORD.form-item-answer.validation.approval.status' | translate)
              : ('RECORD.form-item-answer.validation.rejection.status' | translate)
          "
        ></ui-badge>
      </div>
    </div>
  </div>
</ng-template>

<!-- Loading template -->
<ng-template #loadingTemplate>
  <div class="flex flex-row gap-2 align-items-center h-full">
    <ui-spinner [size]="20"></ui-spinner>
    <span class="text-gray-500">{{
      "COMPONENTS.record-message.decrypting" | translate : { "default": "Decrypting message..." }
    }}</span>
  </div>
</ng-template>

<!-- Rejection panel with reason/ comment -->
<p-overlayPanel
  #validationMessagePromptPanel
  styleClass="generic-overlay-panel no-offset"
  [style]="themingService.customStyles$ | async"
>
  <ng-template pTemplate="content">
    <form (ngSubmit)="rejectFormAnswer(true)">
      <div class="px-3 pt-3 field flex flex-column mb-0" style="--color-focus: var(--primary-color)">
        <label class="bold" for="validationMessage">
          {{
            "RECORD.form-item-answer.validation.rejection.label" | translate : { default: "Optional rejection message" }
          }}
          <i
            class="text-sm pi pi-question-circle"
            tooltipStyleClass="generic-tooltip"
            [pTooltip]="
              'RECORD.form-item-answer.validation.rejection.description'
                | translate
                  : {
                      default:
                        'Would you like to add a reason to the rejection. this could help recipients for filling the item.'
                    }
            "
          ></i>
        </label>
        <input
          type="text"
          id="validationMessage"
          name="validationMessage"
          [autofocus]="true"
          [disabled]="isUpdatingValidationStatus$ | async"
          pInputText
          [(ngModel)]="validationMessage"
        />
      </div>
      <footer class="flex gap-2 justify-content-end py-3 px-3">
        <button
          pButton
          class="p-button-sm p-button-danger w-full"
          [loading]="isUpdatingValidationStatus$ | async"
          [label]="
            validationMessage
              ? ('RECORD.form-item-answer.validation.buttons.reject' | translate : { default: 'Reject answer' })
              : ('RECORD.form-item-answer.validation.buttons.reject-without-reason'
                | translate : { default: 'Reject without reason' })
          "
        ></button>
      </footer>
    </form>
  </ng-template>
</p-overlayPanel>
