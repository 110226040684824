import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { v4 as v4 } from "uuid";
import { DeviceDetectorService } from "ngx-device-detector";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { DateTime, Interval } from "luxon";
import { DateHelper } from "@app/@shared/helpers/date.helper";

@Component({
  selector: "record-form-item-format-calendar",
  templateUrl: "./item-format-calendar.component.html",
  styleUrls: ["./item-format-calendar.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatCalendarComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatCalendarComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Calendar options
  @Input() view: "date" | "month" | "year" = "date";
  @Input() dateFormat: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() defaultDate: Date;
  @Input() disabledDates: Date[];
  @Input() multipleSeparator: string = ",";
  @Input() rangeSeparator: string = "-";
  @Input() inline: boolean = false;
  @Input() showWeek: boolean = false;
  @Input() todayButtonStyleClass: string = "";

  // Time related options
  @Input() showTime: boolean = false;
  @Input() timeOnly: boolean = false;
  @Input() hourFormat: string = "24";
  @Input() stepHour: number = 1;
  @Input() stepMinute: number = 1;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() placeholder: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};
  touchUI: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    if (this.deviceDetectorService.isMobile()) {
      this.inline = false;
      this.touchUI = true;
    }

    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = "item-" + this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    // Get formatters
    if (this.formItem.getFormatters()) {
      // Min date formatter
      let minDateFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MIN_DATE);
      if (minDateFormatter && minDateFormatter.value) {
        let minDate = DateTime.fromISO(minDateFormatter.value.toString()).toJSDate();
        this.minDate = minDate;
      }

      // Max date formatter
      let maxDateFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX_DATE);
      if (maxDateFormatter && maxDateFormatter.value) {
        let maxDate = DateTime.fromISO(maxDateFormatter.value.toString()).toJSDate();
        this.maxDate = maxDate;
      }

      if (!this.timeOnly) {
        // Specific dates formatter
        let specificDatesFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.SPECIFIC_DATES);
        if (specificDatesFormatter && specificDatesFormatter.value) {
          let specificDates = (specificDatesFormatter.value as any[])
            .map((date) => {
              return date instanceof Date ? DateHelper.beginningOfDay(date) : DateTime.fromISO(date).toJSDate();
            })
            .sort();
          this.minDate = specificDates[0];
          this.maxDate = specificDates[specificDates.length - 1];
          this.disabledDates = this.buildDisabledDates(specificDates);
          this.readonly = true;
          this.todayButtonStyleClass = "hidden"; // Hide today button because it overpass disabled dates
        }
      }

      if (this.showTime) {
        // Hour step formatter
        let hourStepFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.HOUR_STEP);
        if (hourStepFormatter && hourStepFormatter.value) {
          let hourStep = Number.parseFloat(hourStepFormatter.value.toString());
          this.stepHour = hourStep;
        }

        // Minute step formatter
        let minuteStepFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MINUTE_STEP);
        if (minuteStepFormatter && minuteStepFormatter.value) {
          let minuteStep = Number.parseFloat(minuteStepFormatter.value.toString());
          this.stepMinute = minuteStep;
        }

        if (this.stepHour || this.stepMinute) {
          let defaultDate = this.minDate ?? new Date();
          // Set min & hour to zero if steps are set to avoid weird times
          this.defaultDate = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0);
        }
      }
    }
  }

  private buildDisabledDates(allowedDates: Date[]): Date[] {
    let minDate = allowedDates[0],
      maxDate = allowedDates[allowedDates.length - 1],
      interval: Interval = Interval.fromDateTimes(minDate, DateTime.fromJSDate(maxDate).plus({ days: 1 }).toJSDate()),
      days = Array.from(this.days(interval));

    return days.filter(
      (date: Date) => !allowedDates.find((allowedDate: Date) => date.getTime() === allowedDate.getTime()),
    );
  }

  private *days(interval: Interval) {
    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
      yield cursor.toJSDate();
      cursor = cursor.plus({ days: 1 });
    }
  }

  handleChange(value) {
    this.onModelTouched();
    this.onModelChange(value);
    this.writeValue(value);
    this.onChange.emit(value);
  }
}
